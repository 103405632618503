import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";
import About from "@/views/About.vue";
import AddMember from "@/views/AddMember.vue";
import Import from "@/views/Import.vue";
import UpdateBuddy from "@/views/UpdateBuddy.vue";
import MemberLookup from "@/views/MemberLookup.vue";
import MemberApproval from "@/views/Approval.vue";
import MemberChecking from "@/views/Checking.vue";
const routes = [
   {
     path: "/",
     name: "Home",
     component: Home,
   },
   {
     path: "/about",
     name: "About",
     component: About,
   },   
   {
     path: "/add_member",
     name: "AddMember",
     component: AddMember,
   },   
   {
     path: "/import",
     name: "Import",
     component: Import,
   },   
   {
     path: "/update_buddy",
     name: "UpdateBuddy",
     component: UpdateBuddy,
   },   
   {
     path: "/member_lookup",
     name: "MemberLookup",
     component: MemberLookup,
   },   
   {
     path: "/approval",
     name: "MemberApproval",
     component: MemberApproval,
   },   
   {
     path: "/checking",
     name: "MemberChecking",
     component: MemberChecking,
   },   
 ];
const router = createRouter({
   history: createWebHistory(),
   routes,
 });
export default router;