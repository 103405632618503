<template>
  <div id="list_members">
      <div class="container my-5">
        <h1>List Members</h1>
        <div id="error_message" hidden>Hi!</div>
        <h3 style="width: 75%; margin-left:auto;
        margin-right:auto;"><input type='text' class="form-control" placeholder="Search Members (Any Field is Searchable)" v-model="search"/></h3>
        <table class="table table-striped" cellpadding=0 cellspacing=0>
          <thead>
            <th>Name</th><th>Wallet ID</th><th>Buddy</th><th>Directs/Total</th><th>Current Deposit</th>
          </thead>
              <tbody v-for="item in filteredSearch" :key="item.id" :v-bind="item.id">
              <tr id="output" >
                  <td>{{item.name}}</td>
                  <td>{{item.wallet_id}}</td>
                  <td>{{item.buddy_name}}</td>
                  <td>{{item.directs}}/{{item.total}}</td>
                  <td>{{item.current_deposit}}</td>
                  <td><button type="button" class="btn btn-dark" @click="showEdit('edit_'+item.id)">Edit</button></td>
              </tr>
                <tr :id="'edit_'+item.id" hidden style="border: 0px solid; padding-bottom: 10px;">
                  <td colspan=100%>
                    <form :id="'form_'+item.id">
                    <table align=center cellpadding='5'>
                      <tr>
                          <td align=left>Name<br /><input type="text" name="name" :value="item.name"></td>
                          <td align=left>Telegram ID<br /><input type="text" name="tg_id" :value="item.tg_id"></td>
                      </tr>
                      <tr>
                          <td align=left>Wallet ID<br /><input type="text" name="wallet_id" :value="item.wallet_id"></td>
                          <td align=left>Welcomed By<br /><input type="text" name="welcomed_by" :value="item.welcomed_by"></td></tr>
                      <tr><td colspan=2 align=left>Notes:<br /><textarea name="notes" v-model="item.notes" cols=45></textarea></td></tr>
                      
                      <tr><td><button id="saveMember" class="w-100 btn btn-primary btn-lg" v-on:click="updateMember($event, 'form_'+item.id)">Save Details</button></td><td><button>Update Directs/Deposits</button></td></tr>
                    </table>
                    </form>
                  </td>
                </tr>
              </tbody>
        </table>
    </div>
  </div>
</template>
<script>
import { API, graphqlOperation } from 'aws-amplify';
// We use ../ here because we are in /views, so we need to go up a level then into /graphql
import { updateMembers } from '../graphql/mutations';
import { memberByApproval, memberByWalletID } from '../graphql/queries';

import {
  getConnection,
  getUserInfo,
  claimsAvailable,
  getContract,
  getDripBalance,
  getUplineCount,
  getBr34pBalance,
} from "../web3tools/Contract";
// import Header from "./Header";

import {
  convertDrip,
  formatPercent,
  shortenAddress,
  backupData,
} from "../web3tools/utils";

console.log(updateMembers, getConnection, getUserInfo, claimsAvailable, getContract, getDripBalance, getUplineCount, getBr34pBalance);
console.log(convertDrip, formatPercent, shortenAddress, backupData);


// import {
//   shortenAddress,
// } from "../web3tools/utils";

export default {
  name: 'MemberLookup',
  async created() {
    this.getAllMembers();
    // this.filtered_members = this.member_return;
  },
  data() {
    return {
      showModal: false,
      search: "",
      member_return: [],
    }
  },
  computed: {
        filteredSearch:function() {
            return this.member_return.filter((member) => {
                var theMember = member.name + member.buddy_name + member.wallet_id;
                return theMember.toLowerCase().match(this.search.toLowerCase());
            })
        }
    },
  methods: {
    toggle(element) {
      return element.hidden = !element.hidden;
    },
    showEdit(editId) {
      // console.log(editId)
      this.toggle(document.getElementById(editId))
    },
    async updateMember(e, formId) {
       e.preventDefault()
       console.log(formId)
      const form = document.getElementById(formId)
      let member_data = []
      for(let field of form.elements) {
      if (field.name) {
          member_data[field.name] = field.value;
      }
    }
    
    var member_id = formId.replace('form_','');
    console.log(member_id)
    
    let contractData = await this.getContractDetails(member_data.wallet_id)
    
    let member_object = {
      id: member_id,
      name: member_data.name,
      wallet_id: member_data.wallet_id,
      tg_id: member_data.tg_id,
      directs: contractData.directs,
      total: contractData.total,
      notes: member_data.notes,
      welcomed_by: member_data.welcomed_by,
      current_deposit: contractData.current_deposit
    }
    
    console.log(member_data.name, contractData, member_object)

    
    await API.graphql({
        query: updateMembers,
        variables: {input: member_object},
      })
      . then (res=>{
        console.log(res);
        let editId = "edit_"+member_id
        this.getAllMembers()
        this.toggle(document.getElementById(editId))
      })
    
      
    },
    async getAllMembers() {
      const members = await API.graphql(graphqlOperation(memberByApproval, { approved: 1, limit: 10000 }))
      console.log(members)
        let member_object = {}
        this.member_return =[]
        Object.values(members.data.memberByApproval.items).forEach(async member =>{
        let member_buddy_name = await this.getBuddyName(member.buddy_id);
        //  console.log(member_buddy_name)
         member_object = {
            "id": member.id,
            "name": member.name,
            "tg_id": member.tg_id,
            "wallet_id": member.wallet_id,
            "notes": member.notes,
            "welcomed_by": member.welcomed_by,
            "buddy_name": member_buddy_name,
            "initial_deposit": member.initial_deposit,
            "current_deposit": member.current_deposit,
            "directs": member.directs,
            "total": member.total,
            "patrick_team": member.patrick_team,
            "approved": member.approved
         }
         this.member_return.push(member_object)
        })
    },
    async getContractDetails(wallet_id) {
      let web3 = web3 ?? (await getConnection());
      let contract = contract ?? (await getContract(web3));
  
        let userInfo = await getUserInfo(contract, wallet_id);
        // console.log(userInfo)
        if (userInfo) {
          let buddy_id = userInfo.upline;
          let initial_deposit = convertDrip(userInfo.deposits)
          let current_deposit = initial_deposit
          let directs = userInfo.referrals
          let total = userInfo.total_structure
          let updateDataReturn = {
            "buddy_id": buddy_id,
            "initial_deposit": initial_deposit,
            "current_deposit": current_deposit,
            "directs": directs,
            "total": total,
          }
          return updateDataReturn
        } else {
          return
        }

    },
     async getBuddyName(buddy_id) {
      const member = await API.graphql(graphqlOperation(memberByWalletID, { wallet_id: buddy_id.toLowerCase() }))
      
      if (member.data.memberByWalletID.items[0]) {
        return member.data.memberByWalletID.items[0].name;
      } else {
        return "Not Found in Member List"
      }
    },
    toggleError(newAddress, showhide, message) {
      let error_field = document.getElementById("error_message");

      if (showhide == "hide") {
          error_field.hidden=true;
        
      } else {
        error_field.hidden=false;
       error_field.innerHTML = message;
      }
    },
  }
}
</script>
<style>
#list_members {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-bottom: 60px;
}
#error_message {
  color:red;
}
#validation {
  color: white;
  background: green;
  margin: 5px;
  border-radius: 5px;
  padding: 5px;
}
</style>