<template>
    <form>
        <input type="file" @change="import_csv">
    </form>
  <div id="output">{{csv_output}}</div>
</template>

<script>

import { API } from 'aws-amplify';
// We use ../ here because we are in /views, so we need to go up a level then into /graphql
import { createMembers } from '../graphql/mutations';


export default {
  name: 'Import',
  async created() {
  },
  data() {
      return {
        csv_output: ''
      }
  },
  methods: {
      async insertMember(member) {
            
            if (!member.wallet_id) { member.wallet_id = 'XXXXXX'} 
            
                let member_data = {
                    "name": member.name,
                    "tg_id": member.tg_id,
                    "wallet_id": member.wallet_id.toLowerCase(),
                    "buddy_id": '0',
                    "patrick_team": '0',
                    "welcomed_by": member.welcomed_by,
                    "added_by": '',
                    "directs": '',
                    "total": '',
                    "initial_deposit": '',
                    "current_deposit": '',
                    "notes": member.notes,
                    "approved": '0'
                }
                
                console.log(member_data)
                
              await API.graphql({
                query: createMembers,
                variables: {input: member_data},
              })
              . then (res=>{
                  console.log("RESPONSE", res, member_data);
                   return res
              })
              .catch (error => {
                  console.log("ERROR CATCH", error, member_data)
              })
            },
      async import_csv(e) {
        //   e.preventDefault();
          const input = e.target.files[0];
          const reader = new FileReader();
          
            function csvToArray(str, delimiter = ",") {
                  // slice from start of text to the first \n index
                  // use split to create an array from string by delimiter
                  const headers = str.slice(0, str.indexOf("\n")).split(delimiter);
                
                  // slice from \n index + 1 to the end of the text
                  // use split to create an array of each csv value row
                  const rows = str.slice(str.indexOf("\n") + 1).split("\n");
                
                  // Map the rows
                  // split values from each row into an array
                  // use headers.reduce to create an object
                  // object properties derived from headers:values
                  // the object passed as an element of the array
                  const arr = rows.map(function (row) {
                    const values = row.split(delimiter);
                    const el = headers.reduce(function (object, header, index) {
                      object[header] = values[index];
                      return object;
                    }, {});
                    return el;
                  });
                
                  // return the array
                  return arr;
                }
    
    
          reader.onload = (e)=> {
            const text = e.target.result;
            const data = csvToArray(text);
            //this.csv_output = data;
            Object.entries(data).forEach(([key, member])=>{
                this.insertMember(member) 
                console.log(key, member)
            });
          };
      
      console.log(reader)
          
      
          
          reader.readAsText(input);
          
      },
    }
}
</script>