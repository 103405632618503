/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMembers = /* GraphQL */ `
  query GetMembers($id: ID!) {
    getMembers(id: $id) {
      id
      name
      tg_id
      wallet_id
      buddy_id
      patrick_team
      welcomed_by
      added_by
      directs
      total
      initial_deposit
      current_deposit
      notes
      approved
      createdAt
      updatedAt
    }
  }
`;
export const listMembers = /* GraphQL */ `
  query ListMembers(
    $filter: ModelMembersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        tg_id
        wallet_id
        buddy_id
        patrick_team
        welcomed_by
        added_by
        directs
        total
        initial_deposit
        current_deposit
        notes
        approved
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const memberByWalletID = /* GraphQL */ `
  query MemberByWalletID(
    $wallet_id: String!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMembersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    memberByWalletID(
      wallet_id: $wallet_id
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        tg_id
        wallet_id
        buddy_id
        patrick_team
        welcomed_by
        added_by
        directs
        total
        initial_deposit
        current_deposit
        notes
        approved
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const memberByBuddyID = /* GraphQL */ `
  query MemberByBuddyID(
    $buddy_id: String!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMembersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    memberByBuddyID(
      buddy_id: $buddy_id
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        tg_id
        wallet_id
        buddy_id
        patrick_team
        welcomed_by
        added_by
        directs
        total
        initial_deposit
        current_deposit
        notes
        approved
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const memberByPatrickTeam = /* GraphQL */ `
  query MemberByPatrickTeam(
    $patrick_team: String!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMembersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    memberByPatrickTeam(
      patrick_team: $patrick_team
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        tg_id
        wallet_id
        buddy_id
        patrick_team
        welcomed_by
        added_by
        directs
        total
        initial_deposit
        current_deposit
        notes
        approved
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const memberByApproval = /* GraphQL */ `
  query MemberByApproval(
    $approved: String!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMembersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    memberByApproval(
      approved: $approved
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        tg_id
        wallet_id
        buddy_id
        patrick_team
        welcomed_by
        added_by
        directs
        total
        initial_deposit
        current_deposit
        notes
        approved
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
