<template>
  <div id="add_member">
      <div class="container my-5">
        <h1>Add Cryptozoa Team Member</h1>
        <div class="row p-4 pb-0 pe-lg-0 pt-lg-5 align-items-left rounded-3 border shadow-lg"> 
        <form>
            <input type="text" v-model="wallet_id" placeholder="Wallet ID" class="form-control" @blur="getUpline($event)"><br />
            <div id="validation" hidden></div>
             <div id="error_message" hidden>Hi!</div>
             <div>
                Buddy: <div id="buddy_id">{{buddy_id}}</div> ({{buddy_name}})<br /><br />
                Patrick Team: <div id="patrick_team"></div><br />
                Deposit at Registration: <div id="initial_deposit">{{initial_deposit}}</div>
            </div>
            <br />
            <div id="verified" hidden>
              <input type="text" v-model="name" placeholder="Telegram Name" class="form-control"><br />
              <input type="text" v-model="tg_id" placeholder="Telegram ID" class="form-control"><br />
              <input type="text" v-model="welcomed_by" placeholder="welcomed_by" class="form-control"><br />
              <textarea v-model="notes" placeholder="Notes" class="form-control"></textarea><br />
              <button id="add_member_button" class="w-50 btn btn-primary btn-lg" v-on:click="createMember($event)" disabled>Add Member</button><br /><br />
            </div>
            <!-- this is the area where we loop all todo items below -->
        </form>
       
        </div>
    </div>
  </div>
</template>
<script>
import { API, graphqlOperation } from 'aws-amplify';
// We use ../ here because we are in /views, so we need to go up a level then into /graphql
import { createMembers } from '../graphql/mutations';
import { memberByWalletID } from '../graphql/queries';

import {
  getConnection,
  getUserInfo,
  claimsAvailable,
  getContract,
  getDripBalance,
  getUplineCount,
  getBr34pBalance,
} from "../web3tools/Contract";
// import Header from "./Header";

import {
  convertDrip,
  formatPercent,
  shortenAddress,
  backupData,
} from "../web3tools/utils";

let patrick_wallets = {
  "Team 1": "0xe42fada2bf9acee93f371c87c7dd81514a9b54e7",
  "Team 2": "0xe51b2ec82b2ee61b1d79af709374f20a11d8dd44",
  "Team 3": "0x2406febd99477fb853c12934bcffd36fb47c09cb",
  "Team 4": "0x14012562bd628c2a7ec223cb6836233a76479940", 
  "Team 5": "0xf2c2990772a34399c9b4ffb0b4e16c1d5e664416",
  "Team 6": "0x19e7abFc8225ce0fa4d3c283b80162f8169b2db3",
  "Team 7": "0xA04EEB343207838F004449Ee965A19bD6a2a69dc",
  "Team 8": "0xBbc085B3f8d17f944A046c414d973370fdA19D66"
};

let uplineData = Array();

console.log(patrick_wallets, getConnection, getUserInfo, claimsAvailable, getContract, getDripBalance, getUplineCount, getBr34pBalance);
console.log(convertDrip, formatPercent, shortenAddress, backupData);
export default {
  name: 'AddMember',
  async created() {
    
  },
  data() {
    return {
      name: '',
      tg_id: '',
      wallet_id: '',
      buddy_id: '',
      buddy_name: '',
      patrick_team: '',
      welcomed_by: '',
      added_by: '',
      directs: '',
      total: '',
      initial_deposit: '',
      current_deposit: '',
      notes: '',
      approved: 1,
      members: []
    }
  },
  methods: {
    async createMember(e) {
      e.preventDefault()
      const { name, tg_id, wallet_id, buddy_id, patrick_team, welcomed_by, added_by, directs, total, initial_deposit, current_deposit, notes, approved } = this;
      if (!name || !wallet_id || !buddy_id || !patrick_team) return;
      const member = { name, tg_id, wallet_id, buddy_id, patrick_team, welcomed_by, added_by, directs, total, initial_deposit, current_deposit, notes, approved };
      this.members = [...this.members, member];
      await API.graphql({
        query: createMembers,
        variables: {input: member},
      })
      . then (res=>{
        console.log(res);
         this.resetData();
          if (confirm("New Member Added!") == true) {
            this.wallet_id = "";
          } else {
            this.wallet_id = "";
          }
      })
    },
    async getBuddyName(buddy_id) {
      const member = await API.graphql(graphqlOperation(memberByWalletID, { wallet_id: buddy_id.toLowerCase() }))
      
      console.log(buddy_id, member)
      if (member.data.memberByWalletID.items[0]) {
      this.buddy_name = member.data.memberByWalletID.items[0].name;
      } else {
        this.buddy_name = "Not Found in Member List"
      }
    },
    async checkExists(check_wallet_id) {
      console.log(check_wallet_id)
      const member = await API.graphql(graphqlOperation(memberByWalletID, { wallet_id: check_wallet_id.toLowerCase() }))
      let exists =  member.data.memberByWalletID.items
      return exists
    },
    async getUpline(e) {
      this.resetData()
      let checkAddress = e.target.value;
      let newAddress = "";
      if (checkAddress.includes("drip.community")) {
        if (checkAddress.includes("https")) {
             newAddress = checkAddress.replace('https://drip.community/faucet?buddy=','');
          } else {
               if (checkAddress.includes("http")) {
                 newAddress = checkAddress.replace('http://drip.community/faucet?buddy=','');
              } else {
                newAddress = checkAddress.replace('drip.community/faucet?buddy=','');   
              }
          }
        } else {
        newAddress = checkAddress;
      }
      newAddress = newAddress.trim().toLowerCase();
      this.wallet_id = newAddress;
      
      let error = false;
      
      if (newAddress.length == "42") {
          this.toggleError(newAddress, "hide", "");
        // Do check stuff
      } else {
        this.toggleError(newAddress, "show", "INVALID Address");
        error = true;
      }
    
     let checkAddressExists = await this.checkExists(this.wallet_id)
     console.log(checkAddressExists)
    
     
      if (error == false) {
        console.log("Made it past the checks", newAddress)
        
        let web3 = web3 ?? (await getConnection());
        let contract = contract ?? (await getContract(web3));
          
        let userInfo = await getUserInfo(contract, newAddress);
        
        if (userInfo) {
        // let uplineCount = await getUplineCount(contract, newAddress);
        
        // document.getElementById("buddy_id").innerHTML = userInfo.upline;
        // document.getElementById("initial_deposit").innerHTML = convertDrip(userInfo.deposits);
       
          this.buddy_id = userInfo.upline;
          // Get the buddy name if they are in the database
          this.buddy_name = this.getBuddyName(this.buddy_id)
          
          this.initial_deposit = convertDrip(userInfo.deposits)
          
          } else {
            
          this.toggleError(newAddress, "show", "Wallet has not deposited to DRIP");
          return
          }
          // Check upline for patrick team
        this.getUplineData(web3, contract, userInfo, userInfo.upline)
          .then(res=>{
            console.log(res)
              if (checkAddressExists.length > 0) {
                 let existsMessage = "ADDRESS ALREADY EXISTS AS CZ TEAM MEMBER - " + checkAddressExists[0].name + "<br />Are you sure you copied their wallet id and not the wallet id of their buddy?"
                 this.toggleError(newAddress, "show", existsMessage);
                 document.getElementById('verified').hidden = true
               }

          })
      
      } else {
        return
      }
      
    },
    toggleError(newAddress, showhide, message) {
      let error_field = document.getElementById("error_message");

      if (showhide == "hide") {
          error_field.hidden=true;
        
      } else {
        error_field.hidden=false;
       error_field.innerHTML = message;
      }
    },
    async getUplineData (web3, contract, userInfo, buddyId) {
      let atDevWallet = false;
      let uplineAddress = buddyId;

      do {
        const uplineInfo = await getUserInfo(contract, uplineAddress);
        const currentAddress = uplineAddress.toLowerCase();
        
      console.log(currentAddress)
        uplineData.push([
          ...uplineData,
          {
            ...uplineInfo,
            address: currentAddress
          },
        ]);
        uplineAddress = uplineInfo.upline;
        
         Object.entries(patrick_wallets).forEach(([key, value]) => {
          console.log(key, value)
            if (value === currentAddress) { 
              document.getElementById('patrick_team').innerHTML = key
              this.patrick_team = value;
              document.getElementById('validation').innerHTML = "Successfully Validated as a Cryptozoa Team Member!!";
              document.getElementById('validation').style.background = "green";              
              document.getElementById('validation').hidden = false;
              document.getElementById('add_member_button').disabled = false
              document.getElementById('verified').hidden = false
              atDevWallet = true; 
            }
         })
        if (uplineInfo.upline.startsWith("0x000")) {
          console.log("NO MATCH")
          document.getElementById('validation').innerHTML = "This wallet is not part of Team Cryptozoa -- Abort Mission!";
          document.getElementById('validation').style.background = "red";
          document.getElementById('patrick_team').innerHTML = ""
          document.getElementById('validation').hidden = false;
          document.getElementById('add_member_button').disabled = true
          document.getElementById('verified').hidden = true
          atDevWallet = true;
        }
      } while (!atDevWallet);
    }, 
    resetData () {
        this.buddy_id = "";
        this.patrick_team = "";
        this.initial_deposit = "";
        this.name = "";
        this.welcomed_by = "";
        this.notes = "";
        this.tg_id = "";
        this.buddy_name = "";
          document.getElementById('patrick_team').innerHTML = ""
          document.getElementById('validation').hidden = true;
          document.getElementById('add_member_button').disabled = true
          document.getElementById('verified').hidden = true
    }
  }
}
</script>
<style>
#add_member {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-bottom: 60px;
}
#error_message {
  color:red;
}
#validation {
  color: white;
  background: green;
  margin: 5px;
  border-radius: 5px;
  padding: 5px;
}
</style>