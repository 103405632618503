/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createMembers = /* GraphQL */ `
  mutation CreateMembers(
    $input: CreateMembersInput!
    $condition: ModelMembersConditionInput
  ) {
    createMembers(input: $input, condition: $condition) {
      id
      name
      tg_id
      wallet_id
      buddy_id
      patrick_team
      welcomed_by
      added_by
      directs
      total
      initial_deposit
      current_deposit
      notes
      approved
      createdAt
      updatedAt
    }
  }
`;
export const updateMembers = /* GraphQL */ `
  mutation UpdateMembers(
    $input: UpdateMembersInput!
    $condition: ModelMembersConditionInput
  ) {
    updateMembers(input: $input, condition: $condition) {
      id
      name
      tg_id
      wallet_id
      buddy_id
      patrick_team
      welcomed_by
      added_by
      directs
      total
      initial_deposit
      current_deposit
      notes
      approved
      createdAt
      updatedAt
    }
  }
`;
export const deleteMembers = /* GraphQL */ `
  mutation DeleteMembers(
    $input: DeleteMembersInput!
    $condition: ModelMembersConditionInput
  ) {
    deleteMembers(input: $input, condition: $condition) {
      id
      name
      tg_id
      wallet_id
      buddy_id
      patrick_team
      welcomed_by
      added_by
      directs
      total
      initial_deposit
      current_deposit
      notes
      approved
      createdAt
      updatedAt
    }
  }
`;
