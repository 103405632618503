<template>
  <div id="add_member">
      <div class="container my-5">
        <h1>Get/Update Buddy</h1>
        <div id="error_message" hidden>Hi!</div>
        <div class="row p-4 pb-0 pe-lg-0 pt-lg-5 align-items-left rounded-3 border shadow-lg"> 
          <div id="output">{{output}}</div>
        </div>
    </div>
  </div>
</template>
<script>
import { API, graphqlOperation } from 'aws-amplify';
// We use ../ here because we are in /views, so we need to go up a level then into /graphql
import { updateMembers } from '../graphql/mutations';
import { listMembers } from '../graphql/queries';

import {
  getConnection,
  getUserInfo,
  claimsAvailable,
  getContract,
  getDripBalance,
  getUplineCount,
  getBr34pBalance,
} from "../web3tools/Contract";
// import Header from "./Header";

import {
  convertDrip,
  formatPercent,
  shortenAddress,
  backupData,
} from "../web3tools/utils";

console.log(getConnection, getUserInfo, claimsAvailable, getContract, getDripBalance, getUplineCount, getBr34pBalance);
console.log(convertDrip, formatPercent, shortenAddress, backupData);
export default {
  name: 'AddMember',
  async created() {
    this.getAllMembers();
  },
  data() {
    return {
      name: '',
      tg_id: '',
      wallet_id: '',
      buddy_id: '',
      buddy_name: '',
      patrick_team: '',
      welcomed_by: '',
      added_by: '',
      directs: '',
      total: '',
      initial_deposit: '',
      current_deposit: '',
      notes: '',
      approved: 0,
      members: [],
      output: ''
    }
  },
  methods: {
    async updateMember(updateData) {
      await API.graphql({
        query: updateMembers,
        variables: {input: updateData},
      })
      . then (res=>{
        console.log(res);
      })
    },
    async getAllMembers() {
      let web3 = web3 ?? (await getConnection());
      let contract = contract ?? (await getContract(web3));

      const members = await API.graphql(graphqlOperation(listMembers, { limit: 10000 }))
      console.log(typeof(members.data.listMembers.items))
      
      Object.values(members.data.listMembers.items).forEach( async member =>{
          let updateData = await this.getUpline(member.wallet_id.trim(), web3, contract)
          updateData.id = member.id
          await this.updateMember(updateData)
      });
    },
    async getUpline(wallet_id, web3, contract) {
      let check_wallet = wallet_id.toLowerCase();
        let userInfo = await getUserInfo(contract, wallet_id);
        
        console.log(check_wallet, userInfo)
        if (userInfo) {
          let buddy_id = userInfo.upline.toLowerCase();
          let current_deposit = convertDrip(userInfo.deposits)
          let directs = userInfo.referrals
          let total = userInfo.total_structure
          let updateDataReturn = {
            "wallet_id": check_wallet,
            "buddy_id": buddy_id,
            "current_deposit": current_deposit,
            "directs": directs,
            "total": total,
            "approved": 1
          }
          
          return updateDataReturn
          
        } else {
              let updateDataReturn = {
                "approved": 9
            }
            return updateDataReturn
          }
    },
      async getPatrickTeam (web3, contract, userInfo, buddyId) {
      let atDevWallet = false;
      let uplineAddress = buddyId;

      let patrick_wallets = {
        "Team 1": "0xe42fada2bf9acee93f371c87c7dd81514a9b54e7",
        "Team 2": "0xe51b2ec82b2ee61b1d79af709374f20a11d8dd44",
        "Team 3": "0x2406febd99477fb853c12934bcffd36fb47c09cb"
      };

      do {
        const uplineInfo = await getUserInfo(contract, uplineAddress);
        const currentAddress = uplineAddress.toLowerCase();
        
        uplineAddress = uplineInfo.upline;
        
        if (Object.values(patrick_wallets).includes(currentAddress)) {
          return currentAddress
        }
        
        if (uplineInfo.upline.startsWith("0x000")) {
          console.log("NO MATCH")
          atDevWallet = true;
          return "nomatch"
        }
      } while (!atDevWallet);
    }, 
    toggleError(newAddress, showhide, message) {
      let error_field = document.getElementById("error_message");

      if (showhide == "hide") {
          error_field.hidden=true;
        
      } else {
        error_field.hidden=false;
       error_field.innerHTML = message;
      }
    },
  }
}
</script>
<style>
#add_member {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-bottom: 60px;
}
#error_message {
  color:red;
}
#validation {
  color: white;
  background: green;
  margin: 5px;
  border-radius: 5px;
  padding: 5px;
}
</style>