<template>
 <amplify-authenticator username-alias="email">
<nav class="navbar navbar-expand-md navbar-dark bg-dark" aria-label="Fourth navbar example">
      <div class="container-fluid">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample04" aria-controls="navbarsExample04" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarsExample04">
          <ul class="navbar-nav me-auto mb-2 mb-md-0">
            <!--<li class="nav-item">-->
            <!--   <router-link to="/" class="nav-link" :active='$route.name=="Home"'>Home</router-link>-->
            <!--</li>-->
            <!--<li class="nav-item">-->
            <!--  <router-link to="/about" class="nav-link">About</router-link>-->
            <!--</li>-->
            <li class="nav-item">
              <router-link to="/add_member" class="nav-link">Add Member</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/member_lookup" class="nav-link">View All Members</router-link>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="dropdown04" data-bs-toggle="dropdown" aria-expanded="false">Approval and Cleanup</a>
              <ul class="dropdown-menu" aria-labelledby="dropdown04">
                <li class="nav-item">
                  <router-link to="/approval" class="nav-link" style="color: #333333">Member Approval</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/checking" class="nav-link" style="color: #333333">Needs Checking</router-link>
                </li>
              </ul>
            </li>
            <li>
                <amplify-sign-out></amplify-sign-out>
            </li>
          </ul>
        </div>
      </div>
    </nav>    
<router-view />
     
 </amplify-authenticator>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}
</style>
